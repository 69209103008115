"use client";

import FilterForm from "@/components/FilterForm";
import AutoGenNormalViewModal from "@/components/modals/AutoGenNormalViewModal";
import http from "@/lib/http";
import { currencyFormat } from "@/utils/helpers";
import { Skeleton } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { TbCurrencyNaira } from "react-icons/tb";
import { useNavigate, useSearchParams } from "react-router-dom";

function BusinessWalletTransactionsClient() {
  // const { user } = useApp();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [wallet, setWallet] = useState<any>(null);

  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    currency: "NGN",
    status: "",
    amount: "",
    order_by: "",
    type: "",
    date_filter_from: "",
    date_filter_to: "",
  };
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchDatas() {
    const wallet_id = searchParams.get("wallet");
    if (wallet_id !== null) {
      try {
        const resp = await Promise.all([
          http("https://dev.lucrestack.co/transactions/view", {
            wallet_id,
            limit: 10,
          }),
          http("https://dev.lucrestack.co/wallet/manager/details", {
            wallet_id,
          }),
        ]);
        console.log("resp", resp[0].data);
        setTransactions(resp[0].data);
        setWallet(resp[1].data[0]);
        setPaginate(resp);
        setIsFetching(false);
      } catch (error) {
        navigate("/d/wallet/details");
        // console.log(error)
        setIsFetching(false);
      }
    } else {
      navigate("/d/wallet/details");
    }
  }

  useEffect(() => {
    // console.log(searchParams.get("wallet"))
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const filteredTransactions = useMemo(() => {
    if (transactions.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? transactions
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
            )
        : sort === "date-desc"
        ? transactions
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
            )
        : sort === "z-a"
        ? transactions
            .slice()
            .sort((a: any, b: any) =>
              b.reference_code.localeCompare(a.reference_code)
            )
        : transactions
            .slice()
            .sort((a: any, b: any) =>
              a.reference_code.localeCompare(b.reference_code)
            );

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            e.reference_code.toLowerCase().includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, transactions]);

  return (
    <>
      <div className="overflow-auto max-w-full md:max-w-full mb-10">
        <div className="text-xs flex text-slate-500 items-center gap-3">
          <div>Business</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Wallet</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Transactions</div>
        </div>
      </div>

      <div className="mb-10 text-xl">Wallet Transactions</div>

      <div className="mb-8">
        {wallet && (
          <div>
            <div className="px-3 py-3">
              <div className="flex gap-3 items-start">
                <div className="bg-green-700 text-neutral-100 sm:p-3 p-1">
                  <TbCurrencyNaira className="w-7 h-7" />
                </div>
                <div className="w-full">
                  <div className="mb-3">
                    <div className="flex gap-1 text-xs">
                      <div>Type:</div>
                      <div className="underline text-orange-600">
                        {wallet.type}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="text-[10px]">Available balance</div>
                    <div>{currencyFormat(wallet.available_balance)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mb-8 flex gap-5 items-center">
        <input
          value={search}
          type="text"
          className="px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Enter Search Keyword"
        />
        <div className="flex-1">
          <div className="text-xs">Sort by</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className="mb-6 border-slate-300 dark:border-neutral-700" />

      <FilterForm
        url="https://dev.lucrestack.co/transactions/view"
        inputs={filterInputs}
        optionalInputs={{ wallet_id: searchParams.get("wallet") }}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setTransactions}
        setPaginationInput={setPaginationInput}
        inputtedIsSelectTag={{
          status: ["Successful", "Pending", "Processing", "Failed"],
        }}
      />

      <div className="mb-10 grid">
        <div className="relative overflow-auto max-h-[500px] flex-1">
          <table className="min-w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Reference code
                </th>
                <th scope="col" className="px-6 py-3">
                  Attached name
                </th>
                <th scope="col" className="px-6 py-3">
                  Attached bank
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!isFetching
                ? filteredTransactions.map((d, k) => (
                    <tr
                      key={k}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {d.reference_code}
                      </td>
                      <td className="px-6 py-4">
                        {d.attach ? d.attach.name : ""}
                      </td>
                      <td className="px-6 py-4">
                        {d.attach ? d.attach.bank : ""}
                      </td>
                      <td className="px-6 py-4">{d.total_amount}</td>
                      <td className="px-6 py-4">{d.type}</td>
                      <td className="px-6 py-4">{d.status}</td>
                      <td className="px-6 py-4">{d.timestamp}</td>
                      <td className="px-6 py-4 flex gap-3">
                        <AutoGenNormalViewModal title="Transaction" data={d}>
                          <span className="font-bold text-blue-600">View</span>
                        </AutoGenNormalViewModal>
                      </td>
                    </tr>
                  ))
                : Array.from(Array(5)).map((k, i) => (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default BusinessWalletTransactionsClient;
