"use client";

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import { httpRaw } from "@/lib/http";
import { handleResponseError } from "@/utils/errors";
import { currencyFormat } from "@/utils/helpers";
import CustomSelect from "../CustomWalletTransferRecipientSelect";
import { IWallet } from "@/types/user.type";
// import { FaHouseUser } from "react-icons/fa";

function TransferWalletModal({
  children,
  data,
  walletDebit,
}: {
  children: React.ReactNode;
  data: IWallet;
  walletDebit: (wallet_id: string, remark: string, amount: string) => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [identifier, setIdentifier] = useState<string>("");
  const [recipient_id, setReceipient] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [tab, setTab] = useState<"internal" | "external">("internal");
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [recipientsInternal, setRecipientsInternal] = useState<any[]>([]);
  const [recipientsExternal, setRecipientsExternal] = useState<any[]>([]);

  async function fetchDatas() {
    try {
      const resp = await Promise.all([
        httpRaw(
          `https://dev.lucrestack.co/wallet/transfer/internal/${data.currency.toLowerCase()}/recipient/details`
        ),
        httpRaw(
          `https://dev.lucrestack.co/wallet/transfer/external/${data.currency.toLowerCase()}/recipient/details`
        ),
      ]);
      // console.log("resp", resp);
      setRecipientsInternal(resp[0].data);
      setRecipientsExternal(resp[1].data);
      setIsFetchingData(false);
    } catch (error) {
      console.log(error);
      setIsFetchingData(false);
    }
  }

  async function walletInternalTransfer() {
    setIsFetching(true);
    try {
      const resp = await httpRaw(
        `https://dev.lucrestack.co/wallet/transfer/internal/${data.currency.toLowerCase()}/initiate/single`,
        { wallet_id: data.wallet_id, recipient_id: identifier, remark, amount }
      );
      // console.log("resp", resp.data);
      walletDebit(data.wallet_id, remark, amount);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  async function walletExternalTransfer() {
    setIsFetching(true);
    try {
      const resp = await httpRaw(
        `https://dev.lucrestack.co/wallet/transfer/external/${data.currency.toLowerCase()}/initiate/single`,
        {
          wallet_id: data.wallet_id,
          recipient_id_id: recipient_id,
          remark,
          amount,
        }
      );
      // console.log("resp", resp.data);
      walletDebit(data.wallet_id, remark, amount);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Wallet Transfer
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="flex gap-5 mb-8">
              <button
                className={classNames("text-[13px]", {
                  "text-purple-600 underline font-bold": tab === "internal",
                  "": tab === "external",
                })}
                onClick={() => {
                  setTab("internal");
                }}
              >
                Internal transfer
              </button>
              {data.type === "general" && (
                <button
                  className={classNames("text-[13px]", {
                    "text-purple-600 underline font-bold": tab === "external",
                    "": tab === "external",
                  })}
                  onClick={() => {
                    setTab("external");
                  }}
                >
                  External transfer
                </button>
              )}
            </div>

            {tab === "internal" ? (
              <div className="mb-3">
                <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                  {currencyFormat(data.available_balance)}
                </div>

                {/* <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Currency</div>
                    <select value={currency} onChange={(e) => setCurrency(e.target.value)} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' disabled={isFetching}>
                      <option value="">Select</option>
                      <option value="ngn">NGN</option>
                      <option value="usd">USD</option>
                      <option value="gpb">GPB</option>
                      <option value="eur">EUR</option>
                    </select>
                  </div> */}

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px] flex gap-2 items-center">
                    <div>Reciepient</div>
                    {isFetchingData && (
                      <ImSpinner8 className="w-3 h-3 animate-spin" />
                    )}
                  </div>
                  <CustomSelect
                    value={identifier}
                    options={recipientsInternal}
                    params={["name"]}
                    setValue={setIdentifier}
                  ></CustomSelect>
                </div>

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Amount
                  </div>
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    placeholder="0.00"
                    disabled={isFetching}
                  />
                </div>

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Remark
                  </div>
                  <input
                    type="text"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    disabled={isFetching}
                  />
                </div>

                <button
                  className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                  onClick={() => walletInternalTransfer()}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <ImSpinner8 className="w-5 h-5 animate-spin" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            ) : (
              <div className="mb-3">
                <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                  {currencyFormat(data.available_balance)}
                </div>

                {/* <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Currency</div>
                    <select value={currency} onChange={(e) => setCurrency(e.target.value)} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' disabled={isFetching}>
                      <option value="">Select</option>
                      <option value="ngn">NGN</option>
                      <option value="usd">USD</option>
                      <option value="gpb">GPB</option>
                      <option value="eur">EUR</option>
                    </select>
                  </div> */}

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px] flex gap-2 items-center">
                    <div>Reciepient</div>
                    {isFetchingData && (
                      <ImSpinner8 className="w-3 h-3 animate-spin" />
                    )}
                  </div>
                  <CustomSelect
                    value={recipient_id}
                    options={recipientsExternal}
                    params={["name", "bank"]}
                    setValue={setReceipient}
                  ></CustomSelect>
                </div>

                {/* className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' */}

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Amount
                  </div>
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    placeholder="0.00"
                    disabled={isFetching}
                  />
                </div>

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Remark
                  </div>
                  <input
                    type="text"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    disabled={isFetching}
                  />
                </div>

                <button
                  className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                  onClick={() => walletExternalTransfer()}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <ImSpinner8 className="w-5 h-5 animate-spin" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            )}
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TransferWalletModal;
