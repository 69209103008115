"use client";

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import { httpRaw } from "@/lib/http";
import { handleResponseError } from "@/utils/errors";
import { currencyFormat, numberCurrencyFormat } from "@/utils/helpers";
import { MPWCheckoutRef } from "mpw-checkout-react";
import { IWallet } from "@/types/user.type";
import { BiCopy } from "react-icons/bi";

function TopupWalletModal({
  children,
  data,
}: {
  children: React.ReactNode;
  data: IWallet;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [amount, setAmount] = useState<string>("");
  // const [option, setOption] = useState<string>("");
  const [isFetching, setIsFetching] = useState(false);
  const [paymentRef, setPaymentRef] = useState("");

  const paymentLinkRef = React.useRef<HTMLDivElement>(null);

  const copyToClipboard = () => {
    if (paymentLinkRef.current) {
      const textToCopy = paymentLinkRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast({
            title: "Success",
            description: "Text copied to clipboard!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  async function topupWallet() {
    setIsFetching(true);
    try {
      const resp = await httpRaw(
        "https://dev.lucrestack.co/wallet/fund/initiate",
        {
          wallet_id: data.wallet_id,
          amount,
          method: ["ussd", "dynamic_virtual_account", "card"],
        }
      );
      // console.log("resp", resp.data)
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // onClose();
      // window.open(resp.data.payment_link, "_blank")
      setPaymentRef(resp.data.reference_code);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  function handleCloseModal() {
    setAmount("");
    setPaymentRef("");
    onClose();
  }

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={handleCloseModal}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Top up wallet
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            {paymentRef.length === 0 ? (
              <div className="mb-3">
                <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                  {currencyFormat(data.available_balance)}
                </div>
                {/* <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Option</div>
                <select value={option} onChange={(e) => setOption(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching}>
                  <option value="">Select</option>
                  <option value="CARD">CARD</option>
                  <option value="BANK_ACCOUNT">BANK ACCOUNT</option>
                  <option value="USSD">USSD</option>
                  <option value="SAVED_CARD">SAVED CARD</option>
                </select>
              </div> */}
                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Amount
                  </div>
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none"
                    disabled={isFetching}
                  />
                </div>

                <button
                  className="px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center"
                  onClick={() => topupWallet()}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <ImSpinner8 className="animate-spin w-5 h-5" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            ) : (
              <div className="space-y-6">
                <div className="text-sm font-bold">
                  {data.currency.toUpperCase()} {numberCurrencyFormat(amount)}
                </div>
                <div>
                  <MPWCheckoutRef
                    orderReferenceCode={paymentRef}
                    onSuccess={() => window.location.reload()}
                    onFailure={() => {
                      toast({
                        title: "Error",
                        description: "Payment failed",
                        status: "error",
                        duration: 10000,
                        isClosable: true,
                      });
                    }}
                  />
                </div>
                <div>
                  <div className="text-sm mb-1 flex gap-2 items-center">
                    <div>Or share link</div>
                    <button onClick={() => copyToClipboard()}>
                      <BiCopy className="w-4 h-4" />
                    </button>
                  </div>
                  <div
                    ref={paymentLinkRef}
                    className="cursor-pointer"
                    onClick={() => copyToClipboard()}
                  >
                    https://checkout.moipayway.com/hosted/{paymentRef}
                  </div>
                </div>
              </div>
            )}
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TopupWalletModal;
